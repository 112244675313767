// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: 'AIzaSyDl6ovENKSW1CFipelCSl2wtne86bMyuz4',
    authDomain: 'vc-beta-01.firebaseapp.com',
    databaseURL: 'https://vc-beta-01.firebaseio.com',
    projectId: 'vc-beta-01',
    storageBucket: 'vc-beta-01.appspot.com',
    messagingSenderId: '391950087962',
    appId: '1:391950087962:web:b03588a833e609f4'
  },
  tempid: 'SLCO',
  locateApiEndpoint: 'https://locate-dot-vc-beta-01.appspot.com/vlcy/secure/'
};
//
//
// export const environment = {
//   production: true,
//   firebase: {
//     apiKey: 'AIzaSyAsHcGnYI66tKImtd9oREAApdBrsXyB3BQ',
//     authDomain: 'vc-locate.firebaseapp.com',
//     databaseURL: 'https://vc-locate.firebaseio.com',
//     projectId: 'vc-locate',
//     storageBucket: 'vc-locate.appspot.com',
//     messagingSenderId: '703456509775',
//     appId: '1:703456509775:web:bb892a5d3a0c770a834f3a'
//   },
//   locateApiEndpoint: 'https://locate-dot-vc-locate.appspot.com/qntx/secure/'
// };
